/* Built In Imports */
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import Breadcrum from '@components/Navigation/Breadcrum';
import OthersCommonUI from '@components/UI/Others/OthersCommonUI';
import HeadComponent from '@components/Utility/HeadComponent';
import config from '@config';
import WrapLayout from '@Layout/WrapLayout';
import { AuthContext } from '@store/auth-context';

/* Services */
import { getLocale } from '@components/Utility/Shared/SharedService';
import {
  fetchFooterData,
  fetchHeaderMenuData,
  fetchHeaderTopMenuData,
} from 'services/commonService';
import { fetchDetails, getLandingPaths } from 'services/othersService';

/* Styles */

/**
 * @param {string} region - Current User Region
 * @param {string} language - Current User Language
 * @param {string} pageData - IdyChallenge Landing Page Data
 */
const IdyChallenge = ({ region, language, pageData }) => {
  const authContext = useContext(AuthContext);
  const router = useRouter();
  // console.log('IdyChallenge0', pageData);

  useEffect(() => {
    authContext.RegionAndLang(region, language);
  }, [authContext, region, language]);

  const pData = JSON.parse(JSON.stringify(pageData));

  if (pData.body?.length && region === 'in' && router.query.referrer_id) {
    pData.body[0].sectionContent[0].button[0].linkUrl = pData.body[0].sectionContent[0].button[0].linkUrl.replace('##REFERRER##', router.query.referrer_id);
    // console.log('1111  ', pData.body[pData.body.length - 1]);
    if (pData.body[pData.body.length - 1].sectionId === 'idy-register-sticky-button') {
      pData.body[pData.body.length - 1].sectionContent[0].linkUrl = pData.body[pData.body.length - 1].sectionContent[0].linkUrl.replace('##REFERRER##', router.query.referrer_id);
      // console.log('IdyChallenge1', pData);
    }
  }
  else {
    // pData.body[0].sectionContent[0].button[0].linkUrl = pData.body[0].sectionContent[0].button[0].linkUrl.split('!')[0] + '!';
    // if (pData.body[pData.body.length - 1].sectionId === 'idy-register-sticky-button') {
    //   pData.body[pData.body.length - 1].sectionContent[0].linkUrl = `${pData.body[pData.body.length - 1].sectionContent[0].linkUrl.split('!')[0]}!`;
    //   // console.log('IdyChallenge1', pData);
    // }
  }

  return (
    <WrapLayout>
      <HeadComponent
        data={pageData?._seoMetaTags || pageData?.seoFields}
        availableLocals={pageData.availableLocales}
        pageconfig={pageData?.pageConfig}
      >
        {' '}
      </HeadComponent>
      <Breadcrum
        page={pageData.breadcrumbs}
        alsoin={pageData.alsoin}
        url=""
        region={region}
        lang={language}
        pageConfig={pageData?.pageConfig}
      />
      <Box as="section" className="page-wrap container-article" padding="0">
        <OthersCommonUI
          pageBody={pData?.body}
          region={region}
          lang={language}
        />
      </Box>
    </WrapLayout>
  );
};

export const getStaticPaths = async () => {
  const eventsLandingPaths = config.USE_LOCAL_URL
    ? [`/in/en/21-day-challenge-idy`, `/us/en/21-day-challenge-idy`]
    : await getLandingPaths('lvl0', '/21-day-challenge-idy');
  return {
    paths: eventsLandingPaths,
    fallback: false,
  };
};

export const getStaticProps = async ({
  preview,
  previewData,
  params: { region, language },
  // req,
}) => {
  const headerTopMenuData = await fetchHeaderTopMenuData(
    getLocale(region, language),
    'isotopmenu'
  );
  const headerMenuData = await fetchHeaderMenuData(
    getLocale(region, language),
    'isomainmenu'
  );
  const footerData = await fetchFooterData(
    getLocale(region, language),
    'isofooter'
  );
  const pageData = await fetchDetails(region, language, '21-day-challenge-idy', {
    preview,
    data: previewData,
  });

  return {
    props: {
      headerTopMenuData: headerTopMenuData?.data || null,
      headerMenuData: headerMenuData?.data || null,
      region: region,
      language: language,
      footerData: footerData?.data || null,
      pageData: pageData || null,
      isPreview: preview ? true : false,
    },
  };
};

export default IdyChallenge;
