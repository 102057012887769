
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[region]/[language]/21-day-challenge-idy",
      function () {
        return require("private-next-pages/[region]/[language]/21-day-challenge-idy/index.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[region]/[language]/21-day-challenge-idy"])
      });
    }
  